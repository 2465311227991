// components/Popup.tsx
import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';

type PopupProps = {
  message: string;
  type: 'success' | 'error' | 'warning';
  onClose: () => void;
  duration?: number;
};

const Popup: React.FC<PopupProps> = ({ message, type, onClose }) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    console.log('Popup mounted');
    setVisible(true); 
    const timer = setTimeout(() => {
      setVisible(false); 
      setTimeout(() => {
        onClose();
      }, 300); 
    }, 3000);
    return () => {
      clearTimeout(timer);
      console.log('Popup unmounted');
    };
  }, [onClose]);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  const getBackgroundColor = () => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'error':
        return 'bg-red-500';
      case 'warning':
        return 'bg-yellow-500';
      default:
        return 'bg-green-500';
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <FaCheckCircle className="mr-2" size={20} />;
      case 'error':
        return <FaTimesCircle className="mr-2" size={20} />;
      case 'warning':
        return <FaExclamationTriangle className="mr-2" size={20} />;
      default:
        return <FaCheckCircle className="mr-2" size={20} />;
    }
  };

  return (
    <div
      className={`${getBackgroundColor()} text-white px-4 py-2 rounded shadow-lg fixed top-4 right-4 z-50 transition-opacity duration-300 transform ${
        visible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
      }`}
    >
      <div className="flex items-center">
        {getIcon()}
        <span>{message}</span>
        <button onClick={handleClose} className="ml-4 text-white font-bold">
          ×
        </button>
      </div>
    </div>
  );
};

export default Popup;
