import React, { useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useRouter } from 'next/router';
import { useAuth } from '../components/AuthContext';
import Popup from '../components/Popup';
import axios, { AxiosError } from 'axios';
import Link from 'next/link';

const LoginPage: React.FC = () => {
  const [loginEmail, setLoginEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [resetEmail, setResetEmail] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const { login } = useAuth();
  const router = useRouter();

  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<'success' | 'error' | null>(null);
  const [isPasswordResetOpen, setIsPasswordResetOpen] = useState<boolean>(false); // 新しい状態変数

  interface LoginResponse {
    access: string;
    refresh: string;
  }

  interface ErrorResponse {
    detail?: string;
    [key: string]: unknown;
  }

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.post<LoginResponse>('/auth/login/', { email: loginEmail, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      if (!accessToken || !refreshToken) {
        throw new Error('アクセストークンまたはリフレッシュトークンが存在しません。');
      }

      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);

      login(accessToken);

      setSuccessMessage('ログインに成功しました！');
      setPopupType('success');

      const { redirect, fav } = router.query;

      setTimeout(() => {
        if (redirect) {
          router.push(`${redirect}?autoFav=${fav || ''}`);
        } else {
          router.push('/');
        }
      }, 3000);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<ErrorResponse>;
        if (axiosError.response?.status === 401) {
          setError('パスワードが間違っています。');
        } else if (axiosError.response?.status === 404) {
          setError('メールアドレスが存在しません。');
        } else if (axiosError.response?.data?.detail) {
          setError(axiosError.response.data.detail);
        } else {
          setError('ログインに失敗しました。');
        }
        setPopupType('error');
      } else if (error instanceof Error) {
        setError(error.message);
        setPopupType('error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const response = await axiosInstance.post('/auth/password_reset/', {
        username,
        email: resetEmail,
        new_password: newPassword,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('パスワードが正常に変更されました！');
        setPopupType('success');

        setTimeout(() => {
          router.push('/login');
        }, 3000);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('バックエンドエラーの詳細:', error.response?.data);
        if (error.response?.data?.detail) {
          setError(error.response.data.detail);
        } else if (error.response?.data) {
          setError(Object.values(error.response.data).flat().join(' '));
        } else {
          setError('パスワード変更に失敗しました。');
        }
      } else {
        console.error('その他のエラー:', error);
        setError('予期しないエラーが発生しました。');
      }
      setPopupType('error');
    } finally {
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    setPopupType(null);
    setSuccessMessage(null);
    setError(null);
  };

  const togglePasswordReset = () => {
    setIsPasswordResetOpen(!isPasswordResetOpen);
    if (isPasswordResetOpen) {
      setUsername('');
      setResetEmail('');
      setNewPassword('');
      setError(null);
      setSuccessMessage(null);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 relative p-4">
      {popupType && (
        <Popup
          message={successMessage || error!}
          type={popupType}
          onClose={handleClosePopup}
          duration={3000}
        />
      )}
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-6">ログイン</h1>
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label htmlFor="loginEmail" className="block text-sm font-medium text-gray-700">
              メールアドレス
            </label>
            <input
              id="loginEmail"
              type="email"
              placeholder="メールアドレス"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
              required
              disabled={loading}
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              パスワード
            </label>
            <input
              id="password"
              type="password"
              placeholder="パスワード"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
            />
          </div>

          <div>
            <button
              type="submit"
              className={`w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? 'ログイン中...' : 'ログイン'}
            </button>
          </div>
        </form>

        {/* パスワード変更ボタン */}
        <div className="mt-4 text-center">
          <button
            onClick={togglePasswordReset}
            className="text-blue-500 hover:text-blue-700 font-semibold"
          >
            {isPasswordResetOpen ? 'パスワード変更を閉じる' : 'パスワードを変更'}
          </button>
        </div>

        {/* パスワード変更フォーム */}
        <div
          className={`mt-6 transition-all duration-300 ease-in-out ${
            isPasswordResetOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-bold">パスワードを変更</h2>
            <button
              onClick={togglePasswordReset}
              className="text-red-500 hover:text-red-700 font-bold text-xl"
              aria-label="パスワード変更フォームを閉じる"
            >
              &times;
            </button>
          </div>
          <form onSubmit={handlePasswordReset} className="space-y-4">
            <input
              type="text"
              placeholder="ユーザー名"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              disabled={loading}
            />
            <input
              type="email"
              placeholder="メールアドレス"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              required
              disabled={loading}
            />
            <input
              type="password"
              placeholder="新しいパスワード"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              disabled={loading}
            />
            <button
              type="submit"
              className={`w-full bg-green-500 text-white py-3 rounded-lg font-semibold hover:bg-green-600 transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? '変更中...' : 'パスワードを変更'}
            </button>
          </form>
        </div>
        {/* 新規登録リンク */}
        <div className="mt-6 text-center">
          <p className="text-sm">
            アカウントをお持ちでないですか？{' '}
            <Link href="/signup" className="text-blue-500 hover:text-blue-700 font-semibold">
              新規登録はこちら
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
